import "./header.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import logo from '../../../Assets/logo12.jpeg';
import { useState } from "react";

export default function Header() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="navbar-container">
      <Navbar
        collapseOnSelect
        expand="lg"
        className="fixed-top navbar-custom"
        variant="dark"
        expanded={expanded}
      >
        <Container>
          {/* Logo */}
          <Navbar.Brand className="navbar-logo">
            <Link to="/">
              <img className="logoo" src={logo} alt="B1 Pub Logo" />
            </Link>
          </Navbar.Brand>

          {/* Toggle Button */}
          <Navbar.Toggle 
            aria-controls="responsive-navbar-nav" 
            onClick={() => setExpanded(expanded ? false : "expanded")} 
          />

          {/* Menu Items */}
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto navbar-items">
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
              <Nav.Link as={Link} to="/about" onClick={() => setExpanded(false)}>About</Nav.Link>
              <Nav.Link as={Link} to="/menu" onClick={() => setExpanded(false)}>Menu</Nav.Link>
              <Nav.Link as={Link} to="/gallery" onClick={() => setExpanded(false)}>Gallery</Nav.Link>
              <Nav.Link as={Link} to="/contact" onClick={() => setExpanded(false)}>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>

          {/* Call Button */}
          <div className="call-us">
            <a href="tel:700000000" className="call-button">Call Us:9900589583</a>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}
