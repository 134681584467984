import Carousel from 'react-bootstrap/Carousel';
import slider3 from '../../../Assets/g1.jpg';
import './inicio.css';
import slider2 from '../../../Assets/g3.jpeg';

export default function slider() {
  return (
    <>
   <div class="mobile-breaks">
    <br/>
    <br/>
    <br/><br/>
</div>
            <Carousel>
        
        <Carousel.Item interval={500}>
          <img
            className="d-block w-100 "
            src={slider2}
            alt="Second slide"
          />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 "
            src={slider3}
            alt="Third slide"
          />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
