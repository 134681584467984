import React from 'react';
import './Gallery.css';
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from '../../../Assets/g1.jpg';
import img2 from '../../../Assets/g5.jpg';
import img3 from '../../../Assets/g3.jpeg';
import img4 from '../../../Assets/g4.jpg';
import img5 from '../../../Assets/g6.jpg';
import img6 from '../../../Assets/g7.jpg';
import img7 from '../../../Assets/g8.jpg';
import img8 from '../../../Assets/g9.jpg';

const Gallery = () => {
  return (
    <>
    <div className='g-all'>

        <div className='g-header'>
            <h1 style={{color:'#cda45e'}}>Gallery</h1>
        </div>

        <div className='g-parent'>
            
        <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img1} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img2} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img3}/>
            </div>

            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img4} />
                </div>
            

           
        </div>


        <div className='g-parent'>
            
            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img5} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img6} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img7} />
                </div>

                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img8}/>
                </div>
                
    
               
            </div>

<br/>
<Nav.Link as={Link} to="/Gallery1">
           <div className='g-button'>
            
            <button style={{borderRadius:'30px'}}>See More</button>
           
            
           </div> 
           </Nav.Link>
            


         
    </div>
    <br/>
    </>
  )
}

export default Gallery