import React from 'react';

import img1 from '../../Assets/g1.jpg';
import img2 from '../../Assets/g5.jpg';
import img3 from '../../Assets/g3.jpeg';
import img4 from '../../Assets/g4.jpg';
import img5 from '../../Assets/g6.jpg';
import img6 from '../../Assets/g7.jpg';
import img7 from '../../Assets/g8.jpg';
import img8 from '../../Assets/g9.jpg';
import img9 from '../../Assets/g12.jpeg';
import img10 from '../../Assets/g11.jpeg';
import img11 from '../../Assets/g10.jpeg';
import img12 from '../../Assets/g16.jpeg';

const Gallery1 = () => {
  return (
    <>
      <br />
      <br />
      <br />

      <div className='g-all'>
        <br />
        <div className='g-header'>
          <h1>Gallery</h1>
        </div>

        <div className='g-parent'>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img1} alt="Gallery Item 1" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img2} alt="Gallery Item 2" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img3} alt="Gallery Item 3" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img4} alt="Gallery Item 4" />
          </div>
        </div>

        <div className='g-parent'>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img5} alt="Gallery Item 5" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img6} alt="Gallery Item 6" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img7} alt="Gallery Item 7" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img8} alt="Gallery Item 8" />
          </div>
        </div>

        <div className='g-parent'>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img9} alt="Gallery Item 9" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img10} alt="Gallery Item 10" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img11} alt="Gallery Item 11" />
          </div>
          <div className='g-photo'>
            <img style={{ borderRadius: '30px' }} src={img12} alt="Gallery Item 12" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery1;
