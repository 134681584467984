import { Link } from "react-router-dom";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../../Assets/logo12.jpeg';
import "./footer.css";

export default function Footer() {
    return (
        <div id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        {/* About B1 Pub Section */}
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-info">
                               
                                <h4>About B1 Pub</h4>
                                <p>B1 Pub is one of the best places in Bangalore to enjoy great drinks, amazing food, and a lively ambiance. With a carefully curated menu and premium cocktails, we offer an unforgettable nightlife experience.</p>
                                <div className="social-links mt-3">
                                <a href="https://www.facebook.com/profile.php?id=100084821779802" className="facebook"><i className="bi bi-facebook" /></a>
                               
                                <a href="https://www.instagram.com/b1_pub/" className="instagram"><i className="bi bi-instagram" /></a>
                            </div>
                            </div>
                        </div>

                        {/* Useful Links Section */}
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/about">About</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/menu">Menu</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/gallery">Gallery</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Contact Us</Link></li>
                            </ul>
                        </div>

                        {/* Contact Info Section */}
                        <div className="col-lg-3 col-md-6 ">
                            <h4>Contact Info</h4>
                            <p>
                                <strong>Address:</strong> 1259, Vidyaranyapura Main Rd, BEL Layout 3rd Block, Bengaluru, Karnataka 560097<br/>
                                <strong>Phone:</strong> +91 099005 89583<br/>
                                <strong>Email:</strong> info@ejemplo.com
                            </p>
                          
                        </div>

                        {/* Map Section */}
                        <div className="col-lg-2 col-md-6">
                           
                            <iframe 
                                className="f-map" 
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4255958874655!2d77.55363183896685!3d13.072191812719073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae22a402822f45%3A0xc46675a4ada35e32!2sB1%20Pub!5e0!3m2!1sen!2sin!4v1709025768812!5m2!1sen!2sin"
                                allowFullScreen
                                loading="lazy"
                                title="B1 Pub Location"
                              
                            ></iframe>
                        </div>

                    </div>
                </div>
            </div>
            <br/>
            <div className="mt-8 text-center text-white text-sm">
		  © {new Date().getFullYear()} B1 Pub. All rights reserved.Developed by RCS Tecnhologies.
		</div>
        </div>
    );
}
