import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './Point.css';
import img4 from '../../../Assets/g3.jpeg';

function acercadeC() {
    return (
        <>
            <br /><br />
            <div id="about" className="about">
                <div className="container">
                    <div className="row align-items-center">
                        
                        {/* Text Content - First on Mobile, Second on Desktop */}
                        <div className="col-lg-6 pt-4 pt-lg-0  content">
                            <h3 className="text-left" style={{ color: '#cda45e' }}>About Us</h3>
                            <br />
                            <p className="fst-italic text-left">
                                B1 Pub is a casual dining pub offering a vibrant ambience and a variety of sumptuous delicacies. 
                                B1 Pub serves North Indian and Italian cuisine. The eclectic bar menu offers an array of drinks 
                                and spirits to choose from. B1 Pub is the perfect place to relax and unwind. It is located in Vidyaranyapura, 
                                West Bangalore and stands Opposite Canara Bank.
                            </p>

                            {/* Features Section with Two Columns */}
                            <div className="points d-flex">
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check-circle-fill"></i> Air Conditioned</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Parking</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Live Screening</li>
                                </ul>
                                <ul className="list-unstyled padd">
                                    <li><i className="bi bi-check-circle-fill"></i> Outdoor Seating</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Free Wi-Fi</li>
                                    <li><i className="bi bi-check-circle-fill"></i> DJ Nights</li>
                                </ul>
                            </div>

                            {/* Read More Button */}
                            <a href="/#/about"><div className="read-more">
                                <button className="btn btn-primary">Read More</button>
                            </div></a>
                        </div>

                        {/* Image Section - Second on Mobile, First on Desktop */}
                        <div className="col-lg-6 pt-5">
                            <div className="about-img">
                                <img src={img4} alt="B1 Pub" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default acercadeC;
