// Testimonial.js
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";

import userIcon from '../../../Assets/34.jpg';

const Testimonial = () => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setTimeout(() => setAnimate(true), 300);
    }, []);

    const testimonials = [
        {
            review: "Amazing ambience and great music! The cocktails were top-notch and the staff was super friendly.",
            name: "Rahul Mehta",
            position: "Food Blogger",
        },
        {
            review: "Best place in Vidyaranyapura to chill with friends. Loved the DJ night and the outdoor seating.",
            name: "Priya Sharma",
            position: "Frequent Visitor",
        },
        {
            review: "Great drinks, delicious food, and fantastic service. Highly recommend the special cocktails!",
            name: "Vikram Singh",
            position: "Pub Enthusiast",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        arrows: false,
    };

    return (
        <div className="testimonial-container">
            <div className="testimonial-image"></div>
            <div className={`testimonial-content ${animate ? "fade-in" : "fade-out"}`}>
                <h3 className="testimonial-subtitle">What Our Guests Say</h3>
                <h2 className="testimonial-title">Creating Unforgettable Nights at B1 Pub</h2>
                <div className="star-rating">
                    {[...Array(5)].map((_, i) => (
                        <span key={i} className="star">★</span>
                    ))}
                </div>
                <Slider {...settings} className="testimonial-slider">
                    {testimonials.map((item, index) => (
                        <div key={index} className="testimonial-slide">
                            <p className="testimonial-review">"{item.review}"</p>
                            <div className="testimonial-author">
                                <img src={userIcon} alt="User" className="testimonial-avatar" />
                                <div>
                                    <h4 className="testimonial-name">{item.name}</h4>
                                    <p className="testimonial-position">{item.position}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="rating-summary">
                    <h3 className="rating-score">4.9</h3>
                    <div className="rating-details">
                        {[...Array(5)].map((_, i) => (
                            <span key={i} className="star">★</span>
                        ))}
                        <p className="review-count">1,200+ Happy Customers</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;