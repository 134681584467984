import React from 'react';
import './Footer1.css';

const Footer1 = () => {
  return (
    <>
    <br/>
    <div className='f-all'>
      <div className='f1'>
            <h1 style={{color:'#cda45e'}}>Reservation </h1>
            <h5 style={{color:'white'}}>B1Pub@gmail.com</h5>
            <h5 style={{color:'white'}}>+91 9900689583</h5>
        </div>  

         <div className='f1'>
        <h1 style={{color:'#cda45e'}}>Open Hours </h1>
        <br/>
        <h5 style={{color:'white'}}>Mon-Sun: 11 am - 11:30 pm</h5>
        
        </div>  

         <div className='f1'>
          <h1  style={{color:'#cda45e'}} >Address</h1>

          <h5 style={{color:'white',textAlign:'',maxWidth:'340px'}}>1259, Vidyaranyapura Main Rd, BEL Layout 3rd Block,Vidyaranyapura, 
          Bengaluru, Karnataka </h5>

        </div>  


    </div>
    <br/>
    
     </>

  )
}

export default Footer1