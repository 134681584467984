import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Inicio from "../layout/users/inicio";
import Menu from "../layout/users/menu";
import Contacto from "../layout/users/contacto";
import Header from "../components/header/headerC";
import Footer from "../components/footer/footerC";
import Gallery1 from "../components/Gallery1";
import About from "../components/about/About";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Inicio} />
        <Route path="/menu" component={Menu} />
        <Route path="/gallery" component={Gallery1} />
        <Route path="/contact" component={Contacto} />
        <Route path="/about" component={About} />
      </Switch>
      <Footer />
    </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;
