
import drinks3 from '../../../Assets/atmos.jpeg'
import drinks2 from '../../../Assets/drink2.jpeg'
import drink1 from '../../../Assets/drink3.jpeg'


function especialesC() {
    return (
        <>
            <section id="specials" className="specials">
                <div className="container" data-aos="fade-up">
                    <div className=" container ">
                        <div className=" section-title ">
                            <div className=" titulo-seccion ">
                                <h2>Discover
                               TRADITION & QUALITY</h2>
                            </div>
                            <p style={{fontSize:"20px"}}>B1 Pub offers many versatile settings so whatever mood you’re in, you can find comfort at either location. </p>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-4">
                            <img className="imgss" src={drink1} alt="" />
                            <h4 className="my-3">Original Drinks</h4>
                           
                        </div>
                        <div className="col-md-4">
                            <img className="imgss" src={drinks2} alt="" />
                            <h4 className="my-3">Delicious Foods</h4>
                            
                        </div>
                        <div className="col-md-4">
                            <img className="imgss" src={drinks3} alt="" />
                            <h4 className="my-3">Great Atmosphere</h4>
                           
                        </div>
                    </div>

                </div>


            </section>
        </>
    );
}

export default especialesC;