import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './About.css';
import img4 from '../../../Assets/g3.jpeg';

function About() {
    return (
        <>
        
             
        <div className="container" style={{paddingTop:'px'}}>
          <div className="section-title">
            <h1 style={{textAlign:'center'}}>About Us</h1>
         
          </div>
            <div id="about" className="about">
            
        </div>
                <div className="container">
                    <div className="row align-items-center">
                        
                        {/* Text Content - First on Mobile, Second on Desktop */}
                        <div className="col-lg-6 pt-4 pt-lg-0  content">
                            
                            <br />
                            <p className="fst-italic text-left">
                                B1 Pub is a casual dining pub offering a vibrant ambience and a variety of sumptuous delicacies. 
                                B1 Pub serves North Indian and Italian cuisine. The eclectic bar menu offers an array of drinks 
                                and spirits to choose from. B1 Pub is the perfect place to relax and unwind. It is located in Vidyaranyapura, 
                                West Bangalore and stands Opposite Canara Bank.
                            </p>

                            {/* Features Section with Two Columns */}
                            <div className="points d-flex">
                                <ul className="list-unstyled">
                                    <li><i className="bi bi-check-circle-fill"></i> Air Conditioned</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Parking</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Live Screening</li>
                                </ul>
                                <ul className="list-unstyled padd">
                                    <li><i className="bi bi-check-circle-fill"></i> Outdoor Seating</li>
                                    <li><i className="bi bi-check-circle-fill"></i> Free Wi-Fi</li>
                                    <li><i className="bi bi-check-circle-fill"></i> DJ Nights</li>
                                </ul>
                            </div>

                            {/* Read More Button */}
                           
                        </div>

                        {/* Image Section - Second on Mobile, First on Desktop */}
                        <div className="col-lg-6 pt-5">
                            <div className="about-img">
                                <img src={img4} alt="B1 Pub" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br/>

            <div className="why-choose section">
    <div className="container text-center">
        <h3 className="section-title">Why Choose B1 Pub?</h3>
        <div className="row justify-content-center">
            <div className="col-12 col-md-6 col-lg-3 mx-2 mb-3" style={{ backgroundColor: '#cda45e', borderRadius: '20px', padding: '20px' }}>
                <i className="bi bi-music-note-beamed icon"></i>
                <h4>Lively Music</h4>
                <p>Enjoy top-notch DJ nights and live performances.</p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mx-2 mb-3" style={{ backgroundColor: '#cda45e', borderRadius: '20px', padding: '20px' }}>
                <i className="bi bi-cup-straw icon"></i>
                <h4>Exclusive Drinks</h4>
                <p>Signature cocktails, beer, and an extensive selection of spirits.</p>
            </div>
            <div className="col-12 col-md-6 col-lg-3 mx-2 mb-3" style={{ backgroundColor: '#cda45e', borderRadius: '20px', padding: '20px' }}>
                <i className="bi bi-basket2-fill icon"></i>
                <h4>Delicious Cuisine</h4>
                <p>A fusion of North Indian and Italian dishes made to perfection.</p>
            </div>
        </div>
    </div>
</div>


        </>
    );
}

export default About;
